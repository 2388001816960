export default {
  authenticationStrategy: 'mobile_phone_or_member_id',
  backend: {
    url: 'https://api.shoprite.dev.cymonzfxip.com/api',
    version: 'v1',
  },
  mock: {
    CBAList: false,
    createBeneficiary: {
      renumerationOptions: {
        externalCash: false,
        mobileWallet: false,
      },
    },
    debitCardsIndex: false,
    user: {
      linkedAccounts: false,
    },
  },
  ozow: {
    is_test: false,
    url: 'https://stagingpay.ozow.com/',
  },
  peach: {
    url: 'https://eu-test.oppwa.com',
  },
  retail: {
    theme: 'tdb',
  },
  retailLayout: {
    transactionSequence: {
      fundingAccountPosition: '',
      recipientPosition: '',
    },
  },
  sentry: {
    currentEnv: 'capetown-shoprite-dev',
    dsn: 'https://7af26b48ae564158a3d4766fe569f570@o1313319.ingest.sentry.io/4504092443082752',
  },
  springBack: {
    message: {
      content: '',
      title: '',
    },
    method: '',
    url: '',
  },
  tenant: {
    uid: '',
  },
}
